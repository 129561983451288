<template>
    <site-header></site-header>
    <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
    <div class="bg-[#f6f7f8]">
        <div class="container">
            <h1 class="md:pt-[76px] md:pb-6 py-2 mx-auto md:text-[44px] text-[24px] font-bold text-center items-center md:w-full w-[75%]"><p>Ваше путешествие начинается здесь</p></h1>
            <p class="full font-normal text-[15px] mx-auto text-center pb-6">Авиабилеты с кэшбэком до 7% на карту Тинькофф</p>
            <p class="mobil font-normal text-[15px] mx-auto text-center pb-6">Авиабилеты с кэшбэком до 7%</p>
            <div class="full flex flex-col justify-start items-stretch">
                <div class="justify-center inline-flex">
                    <div class="rounded py-3 px-4 inline-flex items-center box-border">
                        <div class="relative">
                            <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-12px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                <span>7 %</span>
                            </div>
                            <div class="bg-[#009edc] h-[24px] w-[32px] rounded inline-block relative">
                            </div>
                        </div>
                        <div class="text-[13px] ml-4 leading-5">
                            <p>All Airlines</p>
                        </div>
                    </div>
                    <div class="rounded py-3 px-4 inline-flex items-center box-border">
                        <div class="relative">
                            <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-20px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                <span>до&nbsp;3&nbsp;%</span>
                            </div>
                            <div class="bg-[#000000] h-[24px] w-[32px] rounded inline-block relative">
                            </div>
                        </div>
                        <div class="text-[13px] ml-4 leading-5">
                            <p>Black</p>
                        </div>
                    </div>
                    <div class="rounded py-3 px-4 inline-flex items-center box-border">
                        <div class="relative">
                            <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-12px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                <span>3 %</span>
                            </div>
                            <div class="bg-[#afb6c4] h-[24px] w-[32px] rounded inline-block relative">
                            </div>
                        </div>
                        <div class="text-[13px] ml-4 leading-5">
                            <p>Platinum</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobil">
                <Splide :options="{ rewind: true, perPage: 2, arrows: boolean = false, padding: '8%'}" aria-label="">
                    <SplideSlide class="">
                        <div class="rounded py-3 px-4 inline-flex items-center box-border">
                            <div class="relative">
                                <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-12px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                    <span>7 %</span>
                                </div>
                                <div class="bg-[#009edc] h-[24px] w-[32px] rounded inline-block relative">
                                </div>
                            </div>
                            <div class="text-[13px] ml-4 leading-5">
                                <p>All Airlines</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide class="">
                        <div class="rounded py-3 px-4 inline-flex items-center box-border">
                            <div class="relative">
                                <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-12px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                    <span>3 %</span>
                                </div>
                                <div class="bg-[#000000] h-[24px] w-[32px] rounded inline-block relative">
                                </div>
                            </div>
                            <div class="text-[13px] ml-4 leading-5">
                                <p>Black</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide class="justify-center">
                        <div class="rounded py-3 px-4 inline-flex items-center box-border">
                            <div class="relative">
                                <div class="break bg-[#FFDD2D] rounded-[30px] absolute py-0.5 px-[7px] right-[-12px] top-[-6px] z-[2] font-normal text-[11px] tracking-[.4px]">
                                    <span>3 %</span>
                                </div>
                                <div class="bg-[#afb6c4] h-[24px] w-[32px] rounded inline-block relative">
                                </div>
                            </div>
                            <div class="text-[13px] ml-4 leading-5">
                                <p>Platinum</p>
                            </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide class="justify-center">
                        <div class="rounded py-3 px-4 inline-flex items-center box-border">
                            <div class="relative">
                                <div class="bg-[#afb6c4] h-[24px] w-[32px] rounded inline-block relative">
                                </div>
                            </div>
                            <div class="break text-[13px] ml-4 leading-5">
                                <p class="text-[#336fee] hover:text-[#244ca2]">Другие карты</p>
                            </div>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>

            <app-form url="https://www.tinkoff.ru/travel/flights/iframe/searchform/"/>

            <h2 class="mobil md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] font-bold mx-auto text-center">Здесь начинаются выгодные поездки</h2>
            <div class="full py-6 flex justify-center">
                <div class="md:grid grid-cols-2 gap-x-12">
                    <div class="">
                        <div class="scale bg-[#E2E8F0] rounded-3xl justify-center relative h-full w-full flex">
                            <div class="items-center flex flex-col h-full">
                                <div class="py-10 px-8">
                                    <h3 class="text-center font-medium text-[24px]">Оформите дебетовую карту ALL Airlines</h3>
                                    <p class="text-center pt-3 text">До 10% милями за покупки в Тинькофф Путешествиях,<br>до 1,5% за любые другие</p>
                                </div>
                                <div class="h-60 mt-auto relative">
                                    <img class="relative block" src="@/assets/images/img_1.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="scale bg-[#F0E3E2] rounded-3xl justify-center relative h-full w-full flex">
                            <div class="items-center flex flex-col h-full">
                                <div class="py-10 px-8">
                                    <h3 class="text-center font-medium text-[24px]">Летите сейчас — платите потом</h3>
                                    <p class="text-center pt-3 text">Оплата билетов частями в Тинькофф Путешествиях</p>
                                </div>
                                <div class="h-60 mt-auto relative">
                                    <img class="relative block" src="@/assets/images/img_2.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobil py-6">
                <div class="py-3">
                    <div class="bg-[#E2E8F0] rounded-3xl relative overflow-hidden w-full">
                        <div class="pt-6 pb-8 px-5 text-center">
                            <h3 class="text-center font-bold text-[17px]">Оформите дебетовую карту ALL Airlines</h3>
                            <p class="text-center pt-3 text-[13px] font-normal">До 10% милями за покупки в Тинькофф Путешествиях, до 1,5% за любые другие</p>
                        </div>
                        <div class="h-[200px] relative">
                            <img class="h-[200px] max-w-[200%] absolute right-[-40px]" src="@/assets/images/mobil_img_1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="py-3">
                    <div class="bg-[#f0e3e2] rounded-3xl relative overflow-hidden w-full">
                        <div class="pt-6 pb-8 px-5 text-center">
                            <h3 class="text-center font-bold text-[17px]">Летите сейчас — платите потом</h3>
                            <p class="text-center pt-3 text-[13px] font-normal">Оплата авиабилетов частями<br>в Тинькофф Путешествиях</p>
                        </div>
                        <div class="h-[200px] relative">
                            <img class="h-[200px] max-w-[200%] absolute right-[-100px]" src="@/assets/images/mobil_img_2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:grid grid-cols-3 gap-12 md:py-6">
                <div class="scale box-border max-w-full w-full md:py-0 py-3">
                    <div class="bg-[#EAECEE] rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_3.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Если отменили рейс</h3></div>
                            <div class="full pt-3 md:text-[15px] text-[13px] font-normal"><p>Подайте заявку на возврат</p></div>
                            <div class="mobil pt-3 md:text-[15px] text-[13px] font-normal"><p>Подайте заявку на <a href="" class="text-[#336fee]">возврат</a></p></div>
                        </div>
                    </div>
                </div>
                <div class="scale box-border max-w-full w-full md:py-0 py-3">
                    <div class="bg-[#EAECEE] rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_4.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Когда придет кэшбэк</h3></div>
                            <div class="full pt-3 md:text-[15px] text-[13px] font-normal"><p>Не позднее 30 дней</p></div>
                            <div class="mobil pt-3 md:text-[15px] text-[13px] font-normal"><p>Не позднее <a href="" class="text-[#336fee]">30 дней</a></p></div>
                        </div>
                    </div>
                </div>
                <div class="box-border max-w-full w-full md:py-0 py-3">
                    <div class="scale bg-[#EAECEE] rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_5.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Приведи друга</h3></div>
                            <div class="pt-3 md:text-[15px] text-[13px] font-normal"><p>Делитесь ссылкой и получайте бонус</p></div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">Кэшбэк в Тинькофф Путешествиях</h2>
            <p class="font-normal text-[15px] mx-auto text-center pb-1.5">До 10% рублями, милями или баллами на карту Тинькофф</p>
            <div class="py-6 flex justify-center">
                <div class="md:grid grid-cols-3 gap-x-12">
                    <div class="md:py-0 py-3">
                        <div class="scale bg-[#f1ebd6] rounded-3xl justify-center relative h-full w-full flex">
                            <div class="items-center flex flex-col h-full">
                                <div class="md:py-10 md:px-8 pt-6 px-5 pb-8">
                                    <h3 class="text-center font-bold md:text-[24px] text-[17px]">Отели до 10%</h3>
                                    <p class="text-center pt-3 md:text-[15px] text-[13px] font-normal">Начислим кэшбэк двумя частями<br>за 62 дня</p>
                                </div>
                                <div class="h-60 mt-auto relative">
                                    <img class="relative block" src="@/assets/images/img_6.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:py-0 py-3">
                        <div class="scale bg-[#EAECEE] rounded-3xl justify-center relative h-full w-full flex">
                            <div class="items-center flex flex-col h-full">
                                <div class="py-10 px-8">
                                    <h3 class="text-center font-bold md:text-[24px] text-[17px]">Туры до 5%</h3>
                                    <p class="text-center pt-3 md:text-[15px] text-[13px] font-norma">Начислим кэшбэк двумя частями<br>за 30 дней</p>
                                </div>
                                <div class="h-60 mt-auto relative">
                                    <img class="relative block" src="@/assets/images/img_7.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md:py-0 py-3">
                        <div class="scale bg-[#E2E8F0] rounded-3xl justify-center relative h-full w-full flex">
                            <div class="items-center flex flex-col h-full">
                                <div class="py-10 px-8">
                                    <h3 class="text-center font-bold md:text-[24px] text-[17px]">Ж/д билеты до 5%</h3>
                                    <p class="text-center pt-3 md:text-[15px] text-[13px] font-norma">Начислим кэшбэк в дату выписки</p>
                                </div>
                                <div class="h-60 mt-auto relative">
                                    <img class="relative block" src="@/assets/images/img_8.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 class="full md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">Вот почему нас выбирают и возвращаются</h2>
            <h2 class="mobil md:pt-[76px] pt-12 md:pb-6 pb-2 text-[24px] md:text-[44px] font-bold mx-auto text-center">Почему нас выбирают</h2>
            <div class="md:grid grid-cols-3 gap-12 md:pt-6 pb-24">
                <div class="box-border max-w-full w-full md:py-0 py-3">
                    <div class="bg-[#EAECEE] rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_9.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Цены на билеты от продавцов</h3></div>
                            <div class="mt-3 md:text-[15px] text-[13px] font-normal"><p>Бронируйте билеты онлайн.<br>Ищем низкие цены у продавцов</p></div>
                        </div>
                    </div>
                </div>
                <div class="box-border max-w-full w-full md:py-0 py-3">
                    <div class="bg-[#EAECEE] rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_10.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Кэшбэк за билеты, отели и туры</h3></div>
                            <div class="mt-3 md:text-[15px] text-[13px] font-normal"><p>Вернем до 10% за покупки через Тинькофф Путешествия</p></div>
                        </div>
                    </div>
                </div>
                <div class="box-border max-w-full w-full md:py-0 py-3">
                    <div class="md:bg-[#EAECEE] bg-white rounded-3xl relative h-full w-full">
                        <div class="flex relative md:p-8 px-5 py-6 box-border flex-col h-full">
                            <div class="w-9 h-9 flex relative"><img class="w-full" src="@/assets/images/img_11.svg" alt="cre-IP"></div>
                            <div class="md:pt-11 pt-6"><h3 class="md:font-medium font-bold md:text-[24px] text-[17px]">Сервис и поддержка Тинькофф</h3></div>
                            <div class="mt-3 md:text-[15px] text-[13px] font-normal"><p>Мы всегда на связи в чате приложения</p></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <site-footer :product-links="plinks" disclaimer="" copyright=""></site-footer>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header";
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import ButtonV2 from '@/components/ButtonV2.vue';
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import AppForm from "@/components/app-form.vue";

export default {
    name: 'App',
    // eslint-disable-next-line vue/no-unused-components
    components: {AppForm, GradientDelimiter, SiteHeader, SiteFooter, ButtonV2, Splide, SplideSlide,},

    data: () => ({
        plinks: [],
        phone: process.env.VUE_APP_PHONE,
        urlParams: '',
    }),

    methods: {
        gotoForm() {
            window.scrollTo({
                top: document.getElementById('form-iframe').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }
    },

    created() {
        this.urlParams = window.location.search;
    }
}

</script>

<style lang="scss">

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: #333333;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

.container {
    @media screen and (max-width: 575px) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

h1, h2, h3 {
    font-family: 'TinkoffSans', sans-serif;
    font-weight: 400;
}

h1, h2 {
    font-weight: 700;
    font-variant-ligatures: no-contextual;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0;
    @media screen and (max-width: 575px) {
        font-size: 32px;
        line-height: 35px;
    }
}

h2 {
    @media screen and (max-width: 575px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h3 {
    font-size: 24px;
    line-height: 30px;
}

.header {
    @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
    background: rgba(255, 221, 45, .32) !important;
    color: rgba(0, 0, 0, .8) !important;
}

.numbers {
    font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.shadow {
    box-shadow: 0 0 50px 60px #f6f6f6;
    content: "";
}

.scale:hover {
    transform: scale(1.02, 1.02);
    transition: .4s ease-in-out;
}

.scale:active {
    transform: scale(1, 1);
    transition: .4s ease-in-out;
}

.break {
    word-wrap: break-word
}

.scroll {
    bottom: 24px;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: 15;
}

.line::before {
    background: #dddfe0;
    content: "";
    height: 48px;
    left: -32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
}

@media screen and (max-width: 1100px) {
    .full {
        display: none;
    }
}

@media screen and (min-width: 1100px) {
    .mobil {
        display: none;
    }
}

</style>

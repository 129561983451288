<template>
    <button @click="$emit('action')">
      <slot/>
    </button>
  </template>
  
  <script>
  export default {
    name: "yellow-button",
  }
  </script>
  
  <style scoped>
  button {
    padding: 9px 23px;
    min-height: 42px;
    min-width: 32px;
    transition-duration: .5s;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    border-radius: 5px;
  }
  
  </style>
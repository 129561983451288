<template>
    <iframe width="100%" height="680" :src="returnUrl" scrolling="no" id="form-iframe"/>
</template>

<script>
export default {
    name: "app-form",
    props: ['url', 'tiktokToken', 'tiktokEvents'],
    computed: {
        returnUrl: function () {
            return this.url + '?parent_url=' + encodeURIComponent(document.location.href);
        }
    },
    created: function () {
        window.dataLayer = window.dataLayer || [];

        window.addEventListener("message", function (event) {
            if (typeof event.data === 'object' && event.data.type === 'resultUrl') {
                window.dataLayer.push({'event': 'clk_form_submit'});
                setTimeout(function () {
                    window.location.href = event.data.data + window.location.search.replace('?', '&');
                }, 500);
            }

            if (typeof event.data !== 'string') {
                return;
            }
            const evdata = JSON.parse(event.data);

            if (evdata.type === "appFramePageHeight") {
                const h = evdata.result.height;
                document.getElementById('form-iframe').style.height = (h + 10) + "px";
            } else if (evdata.type === 'analytics') {
                // eslint-disable-next-line no-empty
                window.dataLayer.push({'event': evdata.event});
            }

        }, false);
    }
}

</script>

<style scoped>
iframe {
    border: none;
    overflow: hidden;
}
</style>